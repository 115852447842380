import AwardImg from "../../../assets/mainItems/restaurantAward.png"

import './Awards.css'

const Awards = (params) => {
    return <div className="awards-wrapper" aria-label="Toat Osha Awards">
        <div className='awards-text-wrapper'>
            <div className='awards-title' role="heading" aria-level="2">Best Restaurant in Minneapolis</div>
            <div className='awards-text'>A well-deserved award for Roat Osha according to your visitor reviews at Restaurant Guru, one of the world’s most popular foodie websites with over 30 millions monthly users.</div>
        </div>
        <div className='awards-img-wrapper'>
            <img src={AwardImg} alt="Best restaurant award" className="awards-image" />
        </div>
    </div>
}

export default Awards