import ReviewCard from "./ReviewCard/ReviewCard";
import "./ReviewCards.css";

const ReviewCards = () => {

  const reviewInfo = [
    { id: "re1", review: `"Roat Osha's fresh spring rolls and jalapeño wontons have restorative powers. Incredibly delicious! Pad Thai and basil fried rice do not disappoint. We ate before we could take a picture. Delivery service was excellent as well."`, signer: "Katherine Z." },
    { id: "re2", review: `" Had the best experience there my server Nicole was by far the best server I've had. She recommended entrees that were absolutely amazing. The Thai fried rice was the best in the city. We will definitely come back again! Good prices for happy hour drinks and appetizers."`, signer: "Samuel P." },
    { id: "re3", review: `"Amazing restaurant and wonderful service. Our waiter Troy was very knowledgeable and hospitable! He was very accommodating and friendly. He definitely made out visit worthwhile. Can't wait to come back and enjoy some good Thai food!"`, signer: "Olivia R." },
  ]

  return (
    <div className="review-wrapper" aria-label="Roat Osha Reviews">
      <div className="review-title" role="heading" aria-level="2">
        What people are saying
      </div>
      <div className="review-icons-wrapper">
        {reviewInfo.map((data) => {
          return <ReviewCard key={data.id} reviewInfo={data} />
        })}
      </div>
    </div>
  );
};

export default ReviewCards;
